import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import InformationModal from "../Common/InformationModal";
import BeginnerInstructions from "./BeginnerInstructions";

interface CourseData {
  name: string;
  ref: string;
  day1: string;
  day2: string;
  day3: string;
  signupUrl: string;
  faqUrl: string;
}

const CoursesTable = () => {
  const [showBeginner, setShowBeginner] = useState(false);
  const [signupUrl, setSignupUrl] = useState("");
  const [faqUrl, setFaqUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleBeginnerClose = () => setShowBeginner(false);
  const handleBeginnerShow = (signupUrl: string, faqUrl: string) => {
    setSignupUrl(signupUrl);
    setFaqUrl(faqUrl);
    setShowBeginner(true);
  };
  const [data, setData] = useState([] as CourseData[]);
  const [hasErrored, setHasErrored] = useState(false);

  useEffect(() => {
    const loadRecords = async () => {
      setLoading(true);

      try {
        const response = await fetch(`/.netlify/functions/data?key=courses`);
        const json = await response.json();
        setData(json);
      } catch {
        setHasErrored(true);
      } finally {
        setLoading(false);
      }
    };
    loadRecords();
  }, []);

  return (
    <div >
      
        <div className="text-container">
          <Table size="sm" striped>
            <thead>
              <tr>
                <th
                  className="text-uppercase font-weight-light text-left"
                  scope="col"
                >
                  Name/Ref
                </th>
                <th
                  className="text-uppercase font-weight-light text-left d-none d-sm-table-cell"
                  scope="col"
                >
                  Day 1
                </th>
                <th
                  className="text-uppercase font-weight-light text-left d-none d-sm-table-cell"
                  scope="col"
                >
                  Day 2
                </th>
                <th
                  className="text-uppercase font-weight-light text-left d-none d-sm-table-cell"
                  scope="col"
                >
                  Day 3
                </th>
                <th
                  className="text-uppercase font-weight-light text-left d-sm-none"
                  scope="col"
                >
                  Dates
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((r) => (
                <tr key={r.ref}>
                  <th className="text-left" scope="row">
                    {r.name} <br />
                    <small>{r.ref}</small>
                  </th>
                  <td className="align-middle text-left d-none d-sm-table-cell">
                    {r.day1}
                  </td>
                  <td className="align-middle text-left d-none d-sm-table-cell">
                    {r.day2}
                  </td>
                  <td className="align-middle text-left d-none d-sm-table-cell">
                    {r.day3}
                  </td>
                  <td className="align-middle text-left d-sm-none">
                    {r.day1} <br /> {r.day2} <br /> {r.day3}
                  </td>
                  <td className="align-middle text-left">
                    <Button
                      variant="primary"
                      onClick={() => handleBeginnerShow(r.signupUrl, r.faqUrl)}
                    >
                      Info/Apply &nbsp; <i className="fas fa-chevron-right"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <i className="fas fa-spinner fa-3x fa-spin"></i>}
          {hasErrored && <p>There was an error loading the data</p>}
        </div>


      <InformationModal
        show={showBeginner}
        handleClose={handleBeginnerClose}
        title="Beginner Course Information"
        body={<BeginnerInstructions signupUrl={signupUrl} faqUrl={faqUrl} />}
      />
    </div>
  );
};

export default CoursesTable;
